// src/components/Home.js
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth, firestore } from "../firebase";
import DashboardLayout from "./DashboardLayout";
import "./Home.css";

function Home() {
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleLogout = async () => {
    await auth.signOut();
    history.push("/");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const docRef = firestore.collection("users").doc(user.uid);
          const doc = await docRef.get();
          if (doc.exists) {
            setUserData({ ...doc.data(), email: user.email });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading your dashboard...</p>
      </div>
    );
  }

  return (
    <DashboardLayout onLogout={handleLogout} user={userData}>
      <div className="welcome-section">
        <h1>Welcome back, {userData?.firstName || "Innovator"}!</h1>
        <p className="subtitle">Ready to build something amazing today?</p>
        <div className="dashboard-cards">
          <div className="card quick-actions">
            <h3>Quick Actions</h3>
            <div className="action-buttons">
              <button
                onClick={() => history.push("/application/new")}
                className="action-btn primary"
              >
                Start New Application
              </button>
              <button
                onClick={() => history.push("/submit-concept")}
                className="action-btn secondary"
              >
                Submit Project Concept
              </button>
            </div>
          </div>
        </div>
        <div className="resources-section">
          <h3>Resources to Get Started</h3>
          <div className="resource-cards">
            <a
              href="/application-guide"
              className="resource-card"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>Application Guide</h4>
              <p>Learn how to complete your application</p>
            </a>
            <a href="/success-stories" className="resource-card">
              <h4>Success Stories</h4>
              <p>Get inspired by past winners</p>
            </a>
            <a
              href="/faq"
              className="resource-card"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>FAQ</h4>
              <p>Find answers to common questions</p>
            </a>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Home;
