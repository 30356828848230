// src/components/Homepage.js
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import './Homepage.css';

function Homepage() {
  return (
    <div className="homepage-container">
      {/* Hero Section */}
      <header className="hero">
        <h1>Welcome to YHatch</h1>
        <p>
          Your launchpad for youth entrepreneurship and innovation. Join us to kickstart your startup journey.
        </p>
        <div className="hero-buttons">
          <Link to="/login" className="btn">Login</Link>
          <Link to="/signup" className="btn secondary">Sign Up</Link>
        </div>
      </header>

      {/* Main Content */}
      <section className="intro">
        <h2>About YHatch</h2>
        <p>
          YHatch is dedicated to empowering young founders by providing resources, mentorship, and community support. 
          Whether you're just starting out or looking to scale your startup, we're here to help.
        </p>
      </section>

      {/* Key Features */}
      <section className="intro">
        <h2>What We Offer</h2>
        <div className="features">
          <div className="feature-item">
            <h3>Resources</h3>
            <p>Step-by-step guides and templates to build your startup</p>
          </div>
          <div className="feature-item">
            <h3>Mentorship</h3>
            <p>Connect with experienced founders and advisors</p>
          </div>
          <div className="feature-item">
            <h3>Community</h3>
            <p>Network with other young entrepreneurs</p>
          </div>
        </div>
      </section>

      {/* Simple CTA */}
      <section className="intro">
        <h2>Ready to Get Started?</h2>
        <div className="hero-buttons">
          <Link to="/signup" className="btn">Join YHatch Today</Link>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Homepage;