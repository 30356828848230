// src/components/ProjectConceptForm.js
import React, { useState, useEffect } from 'react';
import { firestore, auth } from '../firebase';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import './MyApplications.css';

function ProjectConceptForm() {
  const { conceptId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [formData, setFormData] = useState({
    projectTitle: '',
    briefDescription: '',
    teamMembers: [{ firstName: '', lastName: '', email: '' }],
    contactEmail: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Add a logout handler so LeftMenu's logout button works
  const handleLogout = async () => {
    await auth.signOut();
    history.push('/login');
  };

  const handleAddTeamMember = () => {
    setFormData({
      ...formData,
      teamMembers: [...formData.teamMembers, { firstName: '', lastName: '', email: '' }]
    });
  };

  const handleTeamMemberChange = (index, field, value) => {
    const updatedMembers = formData.teamMembers.map((member, i) =>
      i === index ? { ...member, [field]: value } : member
    );
    setFormData({ ...formData, teamMembers: updatedMembers });
  };

  useEffect(() => {
    const loadConcept = async () => {
      if (!conceptId) return;

      try {
        const doc = await firestore.collection('projectConcepts').doc(conceptId).get();
        if (doc.exists) {
          const data = doc.data();
          setFormData({
            projectTitle: data.projectTitle,
            briefDescription: data.briefDescription,
            teamMembers: data.teamMembers.map(m => ({
              firstName: m.firstName || '',
              lastName: m.lastName || '',
              email: m.email || ''
            })),
            contactEmail: data.contactEmail
          });
        }
      } catch (error) {
        console.error("Error loading concept:", error);
      }
    };

    // Load concept from state or Firestore
    if (location.state?.conceptData) {
      setFormData(location.state.conceptData);
    } else if (conceptId) {
      loadConcept();
    }
  }, [conceptId, location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('Session expired - please login again');
      if (!formData.projectTitle?.trim()) throw new Error('Project title is required');

      const conceptData = {
        ...formData,
        userId: user.uid,
        updatedAt: new Date(),
        status: 'concept'
      };

      if (conceptId) {
        await firestore.collection('projectConcepts').doc(conceptId).update(conceptData);
      } else {
        conceptData.createdAt = new Date();
        await firestore.collection('projectConcepts').add(conceptData);
      }

      history.push('/applications?submitted=true');
    } catch (err) {
      console.error('Submission error:', err);
      setError(err.message || 'Submission failed. Please try again.');
      if (err.code === 'permission-denied') {
        setError('You don\'t have permission to submit concepts');
      } else if (err.message.includes('auth')) {
        await auth.signOut();
        history.push('/login');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DashboardLayout onLogout={handleLogout}>
      <div className="applications-container concept-form">
        <h2>Submit Project Concept</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Project Title</label>
            <input
              type="text"
              required
              value={formData.projectTitle}
              onChange={(e) => setFormData({ ...formData, projectTitle: e.target.value })}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label>Brief Description</label>
            <textarea
              required
              value={formData.briefDescription}
              onChange={(e) => setFormData({ ...formData, briefDescription: e.target.value })}
              rows="4"
              className="form-textarea"
            />
          </div>

          <div className="team-members">
            <h4>Team Members</h4>
            {formData.teamMembers.map((member, index) => (
              <div key={index} className="member-input-group">
                <input
                  type="text"
                  placeholder="First Name"
                  value={member.firstName}
                  onChange={(e) => handleTeamMemberChange(index, 'firstName', e.target.value)}
                  className="form-input"
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={member.lastName}
                  onChange={(e) => handleTeamMemberChange(index, 'lastName', e.target.value)}
                  className="form-input"
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={member.email}
                  onChange={(e) => handleTeamMemberChange(index, 'email', e.target.value)}
                  className="form-input"
                  required
                />
              </div>
            ))}
            <button 
              type="button" 
              className="add-member-btn"
              onClick={handleAddTeamMember}
            >
              + Add Team Member
            </button>
          </div>

          <div className="form-group">
            <label>Contact Email</label>
            <input
              type="email"
              required
              value={formData.contactEmail}
              onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value })}
              className="form-input"
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="form-actions">
            <button 
              type="button" 
              className="cancel-btn"
              onClick={() => history.goBack()}
              disabled={submitting}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="submit-btn"
              disabled={submitting}
            >
              {submitting ? 'Submitting...' : conceptId ? 'Update Concept' : 'Submit Concept'}
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default ProjectConceptForm;
