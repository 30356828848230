// src/components/DashboardLayout.js
import React from 'react';
import LeftMenu from './LeftMenu';
import Footer from './Footer';
import './DashboardLayout.css';

function DashboardLayout({ children, onLogout, user }) {
  return (
    <>
      <div className="home-wrapper">
        <LeftMenu onLogout={onLogout} user={user} />
        <div className="main-layout">
          <main className="content">{children}</main>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DashboardLayout;
